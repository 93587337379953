import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Masonry from 'react-masonry-css';
import { withPreview } from 'gatsby-source-prismic';

// Components
import { Row } from '../components/global/row';
import Container from '../components/global/container';

const Grid = styled.div`
  margin: 0 15px;

  & .my-masonry-grid {
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;

    margin-bottom: 30px;
  }
  & .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }

  & .my-masonry-grid_column > div {
    margin-bottom: 30px;
  }
`;

const Gallery = ({ data }) => {
  const breakpointColumnsObj = {
    default: 4,
    1200: 3,
    500: 2,
  };

  const galleryContent = data.prismicGallery.data.gallery.map(
    (image, index) => (
      <div key={`gallery_image_${index}`}>
        {image.image.fluid !== null && (
          <img
            srcSet={image.image.fluid.srcSetWebp}
            src={image.image.fluid.srcWebp}
            alt={image.image.alt}
            loading="lazy"
          />
        )}
      </div>
    )
  );

  return (
    <React.Fragment>
      <Helmet title={`Gallery – Mourne Textiles`} />
      <Container marginTop={'60px'}>
        <Row>
          <Grid>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {galleryContent}
            </Masonry>
          </Grid>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default withPreview(Gallery);

export const query = graphql`
  query Gallery {
    prismicGallery {
      data {
        gallery {
          image {
            alt
            fluid(maxWidth: 400) {
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    }
  }
`;
